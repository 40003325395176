import { useDispatch, useSelector } from 'react-redux';
import { payControllerApi } from '../api';
import services from '../services/servicesTeso';
//import servicesIDI from '../../lizst/servicesIDI.js'
import {
    clearErrorMessage, onChecking, onLogin, onLogout, setPagosServicios, upSertConfiguracionesPagos,
    upSertPagosServicios, setBoton, setVerificarLlave, setReportes, setEstadoCargandoTrue, setEstadoCargandoFalse, setPaisEmpresas,
} from '../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useMensajesStore } from './Generales_Mensajes_Validaciones/useMensajesStore';
import { useNavigate } from 'react-router-dom';



export const useAuthStore = () => {

    //debugger
    const navigate = useNavigate();

    const { status, user, errorMessage, estadoCargando } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { abrirCargar, abrirCargarNoTiempo, cerrarLoading } = useMensajesStore();


    const startLogin = async ({ email, password }) => {
        dispatch(onChecking());
        limpiezaDatos();
        try {
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Autorizacion, { email, password });
            //const { data } = await axios.post("http://localhost:4000/api/auth/login", { email, password });
            //http://143.198.139.80/
            if (data.respuesta) {
                //LocalStorage               
                localStorage.setItem('token', data.usuario.token);
                localStorage.setItem('token-init-date', new Date().getTime());
                localStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                localStorage.setItem('payload', data.usuario.email);
                localStorage.setItem('sistema_nombre', "Pay Controller");
                localStorage.setItem('uid', data.usuario.id);
                localStorage.setItem('user', data.usuario);


                //SesionStorage
                sessionStorage.setItem('token', data.usuario.token);
                sessionStorage.setItem('token-init-date', new Date().getTime());
                sessionStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                sessionStorage.setItem('payload', data.usuario.email);
                sessionStorage.setItem('sistema_nombre', "Pay Controller");
                sessionStorage.setItem('uid', data.usuario.id);
                sessionStorage.setItem('user', data.usuario);


                dispatch(onLogin({ name: data.usuario.name + " " + data.usuario.lastName, uid: data.usuario.id }));
                //const sistemasTesoreria = data2.find(sis_nombre === "Tesoreria");  
                // console.log(sistemasTesoreria);
            } else {
                dispatch(onLogout('Credenciales incorrectas'));
                setTimeout(() => {
                    dispatch(clearErrorMessage());
                }, 10);
            }
        } catch (error) {
            console.log("error:" + error);
            dispatch(onLogout('Credenciales incorrectas'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const recoverPassword = async ({ email }) => {
        limpiezaDatos();
        try {
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_RecoverPassword, { email });
            //const { data } = await axios.post("http://localhost:4000/api/auth/login", { email, password });
            //http://143.198.139.80/
            if (data.respuesta) {
                
              Swal.fire({
                icon: 'success',
                title: 'Contraseña enviada',
                text: `Revisa tu correo ingresado por favor.`,
                confirmButtonText: 'Ok'
            });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Correo no existe registrado',
                    text: `Revisa tu correo ingresado por favor.`,
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            console.log("error:" + error);
            dispatch(onLogout('Credenciales incorrectas'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const resetPassword = async ({ id, password }) => {
        limpiezaDatos();
        
        try {
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_ResetPassword, { id, password });
            if (data.respuesta) {
              Swal.fire({
                icon: 'success',
                title: 'Proceso correcto',
                text: `Su contraseña se ha registrado correctamente.`,
                confirmButtonText: 'Ok'
            });
            navigate('/pages/home');

            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Ha ocurrido un error',
                    text: `Por favor reintenta o contacta un administrador.`,
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            console.log("error:" + error);
            dispatch(onLogout('Credenciales incorrectas'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const startRegister = async ({ name,
        lastname,
        identification,
        email,
        password,
        telefono,
        pais,
        estadoProvincia,
        codigoGenerado,
        nombreEmpresa,
        descripcion,
        direccion,
        codigoPostal,
        telefonoEmpresa,
        correoEmpresa }) => {
             abrirCargar();
             limpiezaDatos();
        try {
            //debugger
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Register, { name,
                lastname,
                identification,
                email,
                password,
                telefono,
                pais,
                estadoProvincia,
                codigoGenerado,
                nombreEmpresa,
                descripcion,
                direccion,
                codigoPostal,
                telefonoEmpresa,
                correoEmpresa
             });
             if (data.respuesta) {
                //LocalStorage               
                localStorage.setItem('token', data.usuario.token);
                localStorage.setItem('token-init-date', new Date().getTime());
                localStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                localStorage.setItem('payload', data.usuario.email);
                localStorage.setItem('sistema_nombre', "Pay Controller");
                localStorage.setItem('uid', data.usuario.id);
                localStorage.setItem('user', data.usuario);


                //SesionStorage
                sessionStorage.setItem('token', data.usuario.token);
                sessionStorage.setItem('token-init-date', new Date().getTime());
                sessionStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                sessionStorage.setItem('payload', data.usuario.email);
                sessionStorage.setItem('sistema_nombre', "Pay Controller");
                sessionStorage.setItem('uid', data.usuario.id);
                sessionStorage.setItem('user', data.usuario);

                navigate('/pages/home');
                dispatch(onLogin({ name: data.usuario.name + " " + data.usuario.lastName, uid: data.usuario.id }));
                //const sistemasTesoreria = data2.find(sis_nombre === "Tesoreria");  
                // console.log(sistemasTesoreria);
            } else {
                dispatch(onLogout('Error al registrar'));
                setTimeout(() => {
                    dispatch(clearErrorMessage());
                }, 10);
            }
          
        } catch (error) {
            dispatch(onLogout(error.response.data?.message || '--'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
            Swal.fire({
                icon: 'warning',
                title: 'Aviso:',
                text: error.response.data?.message.message,
                confirmButtonText: 'Ok'
            });
        }
    }

    const GetCodeRegister = async ({ email }) => {
        // dispatch( onChecking() );
        abrirCargarNoTiempo();
        try {

            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_GetCodeRegister, { email });
            if (data.respuesta == "success") {
                cerrarLoading();
                Swal.fire({
                    icon: 'success',
                    title: 'Código generado',
                    text: `Revisa tu correo personal por favor.`,
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            dispatch(onLogout(error.response.data?.msg || '--'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const InicioSesioncheckAuthToken = async (nuevoToken, usuarioURL, pae_id) => {

        try {
            limpiezaDatos();
            //variables recibidas de la URL del Sistema Centralizado
            let token = nuevoToken;
            const USUARIO = usuarioURL;
            const paisId = pae_id;

            //Header para el api
            const headerAxiosListar = {
                'Content-Type': 'application/json',
                'Authorization': token
            }

            //await esperaApi(2);
            //Consulta al API. Se da token y usuario AD para obtener datos personales y las opciones del menu
            const { data } = await axios.post(
                services.API.Auth.ApiAutenticacion_AutorizacionMC,
                {
                    "sis_nombre": "Tesoreria",
                    //pae_id
                    pae_id
                }
                ,
                { headers: headerAxiosListar }
            );

            //await esperaApi(2);
            //Si la consulta es correcta, se guardan los nuevos datos en
            //LocalStorage, SesionStorage y Redux
            if (data.respuesta_tipo === "success") {


                //Se obtiene el nombre del usuario logueado
                let nombreColaborador = data.data.usu_nombre;
                let usuario = data.data.usu_usuario;
                const datosMenu = data.data.menu;
                localStorage.setItem('token', token);
                localStorage.setItem('token-init-date', new Date().getTime());
                localStorage.setItem('Name', nombreColaborador);
                localStorage.setItem('payload', usuario);
                localStorage.setItem('sistema_nombre', 'tesoreria');
                localStorage.setItem('pais_id', pae_id);
                localStorage.setItem('datosOpcionesMenu', JSON.stringify(datosMenu));

                //Se guardan datos en SesionStorage
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('token-init-date', new Date().getTime());
                sessionStorage.setItem('Name', nombreColaborador);
                sessionStorage.setItem('payload', usuario);
                sessionStorage.setItem('sistema_nombre', 'tesoreria');
                sessionStorage.setItem('pais_id', pae_id);
                sessionStorage.setItem('datosOpcionesMenu', JSON.stringify(datosMenu));

                dispatch(onLogin({ name: nombreColaborador, uid: usuario }));

                //Se muestra modal confirmando el inicio de sesión
                Swal.fire(
                    'Exito al iniciar sesión!',
                    'Bienvenido al Sistema de Automatización de procesos de Tesoreria!',
                    'success'
                )


            }
            //Si la consulta al api de warning
            else if (data.respuesta_tipo === "warning") {

                //Se muestra un modal notificando el fallo de inicio de sesión
                Swal.fire(
                    'Verify your data',
                    'Incorrect credentials',
                );
                //Se cambia el status de Redux a logout para que se muestre el formulario de               
                dispatch(onLogout());

            }
            //Se la consulta al api da error
            else if (data.respuesta === "error") {

                //Se setea en False para que se deje de mostrar el spinner de iniciar sesión           
                //Se muestra un modal con el error
                Swal.fire({
                    icon: 'error',
                    title: 'Error en BD',
                    text: data.respuesta_detalle,
                    footer: ''
                }
                );
                dispatch(onLogout());


            }

            // En caso de un error al intentar realizar la consulta al api
        } catch (error) {

            //Se cambia el status de Redux a logout para que se muestre el formulario de
            //inicio de sesión
            dispatch(onLogout());

            //Se muestra un modal con el error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
                footer: ''
            })

        }
    }

    const limpiezaDatos = () => {
        //Limpieza local y de sesion
        localStorage.clear();
        sessionStorage.clear();
        dispatch(upSertPagosServicios(['']));
        dispatch(setPagosServicios(['']));
        dispatch(setBoton(['']));
        dispatch(setVerificarLlave(['']));
        dispatch(upSertConfiguracionesPagos(['']));
        dispatch(setReportes(['']));
    }


    const checkAuthToken = async () => {
        // debugger
        const token2 = sessionStorage.getItem('token');
        const token = localStorage.getItem('token');
        if (!token && !token2) return dispatch(onLogout());

        try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
            //const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Validacion, { token });
            //const { data } = await calendarApi.get('auth/renew');
            const nombre = sessionStorage.getItem('Name');
            const usuario = sessionStorage.getItem('payload');
            const sistema = sessionStorage.getItem('sistema_nombre');
            const user = sessionStorage.getItem('user');
            const id = sessionStorage.getItem('uid');


            if (!nombre) {
                return dispatch(onLogout());
            }

            localStorage.setItem('Name', nombre);
            localStorage.setItem('payload', usuario);
            localStorage.setItem('token', token2);
            localStorage.setItem('token-init-date', new Date().getTime());
            localStorage.setItem('sistema_nombre', sistema);
            //sessionStorage.clear();
            //dispatch( onLogout() );
            dispatch(onLogin({ name: nombre, uid: id }));
        } catch (error) {
            localStorage.clear();
            dispatch(onLogout());
        }
    }

    const verificaPais = async () => {
        try {
            debugger

            //variables recibidas de la URL del Sistema Centralizado
            let token = undefined;
            let sisId;

            if (token == undefined) {
                token = sessionStorage.getItem('token');
                sisId = sessionStorage.getItem('sistema_id');
            }

            //Header para el api
            const headerAxiosListar = {
                'Content-Type': 'application/json',
                'Authorization': token
            }

            //await esperaApi(2);
            //Consulta al API. Se da token y usuario AD para obtener datos personales y las opciones del menu
            const { data } = await axios.post(
                services.API.Auth.ApiAutenticacion_Verificacion_Pais,
                {
                    // sisId // id del sistema enviado por el token
                    "sis_id": 1,
                }
                ,
                { headers: headerAxiosListar }
            );

            //await esperaApi(2);
            //Si la consulta es correcta, se guardan los nuevos datos en
            //LocalStorage, SesionStorage y Redux
            if (data.respuesta_tipo === "success") {


                //Se obtienen los datos de las empresas    

                let paisEmpresa = (data.data);
                console.log(JSON.stringify(data.data));

                // localStorage.setItem("Paises y empresas", paisEmpresa);
                localStorage.setItem('paisEmpresa', JSON.stringify(paisEmpresa));
                sessionStorage.setItem('paisEmpresa', JSON.stringify(paisEmpresa));

                dispatch(setPaisEmpresas({ data: data.data }));

                // return paisEmpresa; 

                // dispatch( onLogin({ name: nombreColaborador, uid: usuario}));   

            }
            //Si la consulta al api de warning
            else if (data.respuesta_tipo === "warning") {

                //Se muestra un modal notificando el fallo de inicio de sesión
                Swal.fire(
                    'Verify your data',
                    'Incorrect credentials',
                );
                //Se cambia el status de Redux a logout para que se muestre el formulario de               
                //dispatch( onLogout() );

            }
            //Se la consulta al api da error
            else if (data.respuesta_tipo === "error") {

                //Se setea en False para que se deje de mostrar el spinner de iniciar sesión           
                //Se muestra un modal con el error
                Swal.fire({
                    icon: 'error',
                    title: 'Error en BD',
                    text: data.respuesta_detalle,
                    footer: ''
                }
                );
                // dispatch( onLogout() );


            }

            // En caso de un error al intentar realizar la consulta al api
        } catch (error) {

            //Se cambia el status de Redux a logout para que se muestre el formulario de
            //inicio de sesión
            dispatch(onLogout());

            //Se muestra un modal con el error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
                footer: ''
            })

        }
    }

    const getCountries = async () => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            //debugger
            //abrirCargar();
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            //console.log("url:" + services.API.Category.List);
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Countries, 
             { }, {headers: headerAxios});            
            if (data.respuesta === "success") {
                return data.Data;
            } else {
                if (data.respuesta_tipo === "warning") {
                    NoPermisoMensaje();
                }
                if (data === "") {
                    NoPermisoMensaje();
                }
            }
        } catch (error) {
            ErrorMensaje();
            console.log("error:" + error);
            throw error; // Puedes propagar el error para manejarlo en el componente
        }
    }

    const startLogout = () => {
        let email = sessionStorage.getItem('payload');
        axios.post(services.API.Auth.ApiAutenticacion_Logout, { email });
            
        localStorage.clear();
        sessionStorage.clear();
        limpiezaDatos();

        let uri_logout = "";

        switch (process.env.REACT_APP_API_AMBIENT) {
            case 'Dev':
                uri_logout = process.env.REACT_APP_LOGOUT_DEV;
                break;
            case 'Local':
                uri_logout = process.env.REACT_APP_LOGOUT_LOCAL;
                break;
            case 'Prod':
                uri_logout = process.env.REACT_APP_LOGOUT_PROD;
                break;
        }
        //navigate('/auth/login');

        window.location.href = uri_logout;
        //console.log(uri_logout);
        //window.location.href = process.env.REACT_APP_LOGOUT_LOCAL;
        //window.location.href = 'https://10.161.252.108:3003';
        //dispatch(onLogout());
    }

    return {
        //* Propiedades
        errorMessage,
        status,
        user,

        //* Métodos
        checkAuthToken,
        startLogin,
        recoverPassword,
        resetPassword,
        startLogout,
        startRegister,
        verificaPais,
        abrirCargar,
        InicioSesioncheckAuthToken,
        GetCodeRegister,
        estadoCargando,
        getCountries,
    }

}