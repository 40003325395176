import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthStore, useForm } from "../../hooks";
import Swal from "sweetalert2";
import logo from "../../styles/assets/img/Logo.jpg";
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLock, faArrowRight  } from '@fortawesome/free-solid-svg-icons';


const loginFormFields = {
  loginUser: "",
  loginPassword: "",
};

const recoveryFormFields = {
  recoveryEmail: "",
};

export default function Signin() {
  const { startLogin, errorMessage, recoverPassword } = useAuthStore();
  const { loginUser, loginPassword, onInputChange: onLoginInputChange } = useForm(loginFormFields);
  const { recoveryEmail, onInputChange: onRecoveryInputChange } = useForm(recoveryFormFields);

  const [showRecoveryModal, setShowRecoveryModal] = useState(false);

  const loginSubmit = (event) => {
    event.preventDefault();
    startLogin({ email: loginUser, password: loginPassword });
  };

  const passwordRecoverySubmit = (event) => {
    event.preventDefault();
    recoverPassword({ email: recoveryEmail });
    setShowRecoveryModal(false); // Cierra el modal después de enviar
    //Swal.fire("Correo enviado", "Por favor revisa tu correo para recuperar tu contraseña", "success");
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      Swal.fire("Error en la autenticación", errorMessage, "error");
    }
  }, [errorMessage]);

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ boxShadow: "0px 4px 80px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header>
          <img src={logo} alt="Logo" style={{ width: "100%", height: "auto", alignItems: "center" }} />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={loginSubmit} style={{ margin: "5%" }}>
          <div className="mb-4">
  <Form.Label style={{ color: "#2796cf", borderRadius: "20%" }}>Correo *</Form.Label>
  <InputGroup>
    <InputGroup.Text>
      <FontAwesomeIcon icon={faEnvelope} />
    </InputGroup.Text>
    <Form.Control
      type="text"
      placeholder="Usuario"
      name="loginUser"
      value={loginUser}
      onChange={onLoginInputChange}
    />
  </InputGroup>
</div>

<div className="mb-4">
  <Form.Label style={{ color: "#2796cf" }}>Contraseña *</Form.Label>
  <InputGroup>
    <InputGroup.Text>
      <FontAwesomeIcon icon={faLock} />
    </InputGroup.Text>
    <Form.Control
      type="password"
      placeholder="Ingrese su contraseña"
      name="loginPassword"
      value={loginPassword}
      onChange={onLoginInputChange}
    />
  </InputGroup>
</div>
            <Button type="submit" variant="primary" className="btn-sign">
            Ingresar <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '8px' }} />

            </Button>
          </Form>
        </Card.Body>
        <hr />
        <Card.Footer style={{ color: "#2796cf" }}>
          ¿No tiene cuenta?{" "}
          <Link to="/pages/signup">
            <strong>Registrarse</strong>
          </Link>
        </Card.Footer>

        <div className="separator">
          <span>O</span>
        </div>

        <Card.Footer style={{ color: "#2796cf" }}>
          ¿Olvidó su contraseña?{" "}
          <Button variant="link" onClick={() => setShowRecoveryModal(true)}>
            <strong style={{ color: "#000000" }}>Recuperar contraseña</strong>
          </Button>
        </Card.Footer>
        <div className="separator">
          <span>O</span>
        </div>
        <Card.Footer>
          <Card.Text style={{ textAlign: "center" }}>
          <a href="https://wa.me/50661372755" target="_blank" rel="noopener noreferrer" style={{ color: "#2796cf", display: "flex", alignItems: "center", gap: "8px" }}>
      <FontAwesomeIcon icon={faWhatsapp} size="2x" /> Contáctanos por WhatsApp
    </a>
          </Card.Text>
          <br />
          <Card.Title style={{ color: "#2796cf" }}>
            Powered by <a href="https://www.jmsoftcr.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#2796cf" }}>JMSOFTCR</a> &copy; 2025
          </Card.Title>
        </Card.Footer>
      </Card>

      {/* Modal de Recuperación de Contraseña */}
      <Modal show={showRecoveryModal} onHide={() => setShowRecoveryModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={passwordRecoverySubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese su correo electrónico"
                name="recoveryEmail"
                value={recoveryEmail}
                onChange={onRecoveryInputChange}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Recuperar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
