import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Grid, _ } from "gridjs-react";
import { h } from "gridjs";
import { Card, Button, Container, Form } from "react-bootstrap";
import { useCustomerStore } from '../../hooks/Mantenimiento_Generales/useCustomerStore';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function Standard() {
  const [Data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(""); // Estado para el valor del filtro
  const { list, getDataPaymentUpdate } = useCustomerStore();
  const navigate = useNavigate();

  useEffect(() => {
      getList();
  }, []);

  useEffect(() => {
    applyFilter(); // Aplicar el filtro cada vez que cambie el valor del filtro o los datos
  }, [filter, Data]);

  const getList = async () => {
    try {
      let data = await list();
      data = data.map(item => {
        item.payment_date = item.payment_date.substring(0, 10);
        item.next_payment_date = item.next_payment_date.substring(0, 10);
        item.notify = item.notify == 0 ? "NO" : "SI";
        item.statusPayment = item.statusPayment === "Paid" 
        ? "Pagado" 
        : item.statusPayment === "Overdue" 
            ? "Vencido" 
            : item.statusPayment === "Pending"
                ? "Pendiente"
                : item.statusPayment; // Mantiene el valor si no coincide con ninguna condición
            return item;
      });
      setData(data);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const applyFilter = () => {
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = Data.filter(item => {
      return Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredData(filteredData);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleGetData = async (id) => {
    try {
      let customerData = await getDataPaymentUpdate(id);
      customerData = customerData.map(item => {
        item.payment_date = item.payment_date.substring(0, 10);
        item.next_payment_date = item.next_payment_date.substring(0, 10);
        return item;
      });
      navigate(`/pages/customerPaymentEdit/`, { state: { customerData } });
    } catch (error) {
      console.log("error:" + error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "CustomerData.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Name', 'Description', 'Status']],
      body: filteredData.map(({ name, description, status }) => [name, description, status])
    });
    doc.save('CustomerData.pdf');
  };

  return (
    <React.Fragment>
      <Container fluid="md" className="mb-5">
        <Header />
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Control Pagos Clientes</h4>
            </div>
            <div className="ml-2">
            <Form.Control 
              type="text" 
              placeholder="Search..." 
              value={filter} 
              onChange={handleFilterChange} 
              className="ml-2"
            />
            </div>
            
            <div className="ml-2">
              <Button variant="dark" onClick={exportToExcel} className="ml-2">Exportar Excel</Button>
              {/* <Button variant="dark" onClick={exportToPDF} className="ml-2">Exportar PDF</Button> */}
            </div>
          </div>
          <Card.Body style={{ overflowX: 'auto' }}>
            <Grid
              data={filteredData}
              columns={[
                { name: 'id', hidden: true },
                { id: 'identification', name: 'Identificacion' },
                { id: 'name', name: 'Nombre' },
                { id: 'phone', name: 'telefono' },
                { id: 'notify', name: 'Notificado' },
                // { id: 'email', name: 'Email' },
                { id: 'statusPayment', name: 'Estado' },
                { id: 'payment_date', name: 'Fecha de Pago' },
                { id: 'next_payment_date', name: 'Proximo Pago' },
                {
                  name: 'Editar',
                  formatter: (cell, row) => {
                    return h('Button', {
                      className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-warning bg-blue-600',
                      onClick: () => handleGetData(`${row.cells[0].data}`)
                    }, 'Editar');
                  }
                },
              ]}
              search={false}
              pagination={{
                enabled: true,
                limit: 5
              }}
              sort={true}
              resizable={true}
              language={{
                pagination: {
                  previous: 'Previous',
                  next: 'Next',
                  navigate: (page, pages) => `Page ${page} of ${pages}`,
                  page: (page) => `Page ${page}`,
                  showing: 'Showing of',
                  of: 'of',
                  to: 'to',
                  results: 'records',
                },
                loading: 'Loading...',
                noRecordsFound: 'No matches found.',
                error: 'An error occurred while obtaining the data.',
              }}
              className={{
                table: 'table table-bordered mb-0'
              }}
            />
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
