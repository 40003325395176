
const maintenance = [
  {
    "label": "Dashboard",
    "link": "/pages/home",
    "icon": "ri-dashboard-line",
  },
  {
    "label": "Clientes",
    "link": "/pages/Customer",
    "icon": "ri-user-2-fill",
  },
  {
    "label": "Categoria",
    "link": "/pages/Category",
    "icon": "ri-bar-chart-horizontal-fill",
  },
  {
    "label": "Proveedores",
    "link": "/pages/Supplier",
    "icon": "ri-building-2-line",
  },
  {
    "label": "Almacenes",
    "link": "/pages/Warehouse",
    "icon": "ri-store-fill",
  },
  {
    "label": "Cargar Clientes Masivo",
    "link": "/pages/customerUpload",
    "icon": "ri-user-2-fill",
  },
  {
    "label": "Productos",
    "link": "/pages/Product",
    "icon": "ri-handbag-fill",
  }
];

const transactions = [
  {
    "label": "Control Pagos Clientes",
    "link": "/pages/CustomerPayment",
    "icon": "ri-file-text-line",
  },
  {
    "label": "Envia Recordatorio Pago",
    "link": "/pages/CustomerMessageWS",
    "icon": "ri-file-text-line",
  },
  {
    "label": "Inventario X Factura",
    "link": "/pages/Inventory",
    "icon": "ri-dashboard-line",
  },
  {
    "label": "Inventario Productos",
    "link": "/pages/InventoryProduct",
    "icon": "ri-dashboard-fill",
  }
];
const invoice = [
  {
    "label": "Registered invoices",
    "link": "/pages/invoiceRg",
    "icon": "ri-file-text-line",
  }
];
const info = [
  {
    "label": "Información",
    "link": "/pages/info",
    "icon": "ri-file-text-line",
  },
  {
    "label": "Soporte Técnico",
    "link": "/pages/soporte",
    "icon": "ri-file-text-line",
  }
];
const solpagMenu = [ 
   // {
  //   "label": "Solicitud de pagos",
  //   "link": "/pages/solicitudes",
  //   "icon": "ri-file-text-line",
  // },
  // {
  //   "label": "Solicitudes Pendientes",
  //   "link": "/pages/pagosAdmin",
  //   "icon": "ri-file-text-line",
  // },
  // {
  //   "label": "Solicitudes para Revision",
  //   "link": "/pages/solicitudPagosEditar",
  //   "icon": "ri-file-text-line",
  // },
];
const MantenimientosGeneralesMenu = [ 
  {
    // "label": "Mantenimiento Pagos",
    // "icon": "ri-list-check-2",
    // "submenu": [
    //   {
    //     "label": "Configuracion de Pagos de Servicios",
    //     "link": "pages/registroPagoConfiguracion"
    //     "icon": "ri-file-text-line",
    //   },
    //   {
    //     "label": "Transaccion",
    //     "link": "/pages/transaccion"        
    //   },
    //   {
    //     "label": "Tipo Solicitud",
    //     "link": "/pages/tipoSolicitud"
    //   },
    //   {
    //     "label": "Método Pago",
    //     "link": "/pages/metodoPago"
    //   },
    //   {
    //     "label": "Moneda",
    //     "link": "/pages/moneda"
    //   },
    //   {
    //     "label": "Moneda Por País",
    //     "link": "/pages/monedaXOperativa"
    //   },
    //   {
    //     "label": "Tipo de Pago",
    //     "link": "/pages/tipoPago"
    //   },
    //   {
    //     "label": "Recurrencia de Pago",
    //     "link": "/pages/recurrenciaPago"
    //   },
    //   {
    //     "label": "Recurrencia de Pago Por Operativa",
    //     "link": "/pages/recurrenciaXOperativa"
    //   },
    //   {
    //     "label": "Tipo Solicitud Operativa",
    //     "link": "/pages/solicitudOperativa"
    //   },
    //   {
    //     "label": "Pago Por Operativa",
    //     "link": "/pages/pagoXOperativa"
    //   },
    //   {
    //     "label": "Método Pago Por Operativa",
    //     "link": "/pages/metPagoXOperativa"
    //   },
    //   {
    //     "label": "Personas",
    //     "link": "/pages/personasCuentas"
    //   },
   // ]
  }
];

const pagesMenu = [
  {
    "label": "User Pages",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "Pagina Standard",
        "link": "/pages/standard"
      },
      {
        "label": "User Profile",
        "link": "/pages/profile"
      },
      {
        "label": "People & Groups",
        "link": "/pages/people"
      },
      {
        "label": "Activity Log",
        "link": "/pages/activity"
      },
      {
        "label": "Events",
        "link": "/pages/events"
      },
      {
        "label": "Settings",
        "link": "/pages/settings"
      }
    ]
  },
  {
    "id": 27,
    "label": "Authentication",
    "icon": "ri-lock-2-line",
    "submenu": [
      {
        "label": "Sign In Basic",
        "link": "/pages/signin"
      },
      {
        "label": "Sign In Cover",
        "link": "/pages/signin2"
      }
    ]
  },
  {
    "label": "Error Pages",
    "icon": "ri-error-warning-line",
    "submenu": [
      {
        "label": "Pagina de error",
        "link": "/pages/error-404"
      }
    ]
  },
  {
    "label": "Other Pages",
    "icon": "ri-file-text-line",
    "submenu": [
      {
        "label": "Pagina Matriz",
        "link": "/pages/pricing"
      },
      {
        "label": "FAQ",
        "link": "/pages/faq"
      }
    ]
  }
];

const uiElementsMenu = [
  {
    "label": "Getting Started",
    "icon": "ri-pencil-ruler-2-line",
    "submenu": [
      {
        "label": "Grid System",
        "link": "/docs/layout/grid"
      },
      {
        "label": "Columns",
        "link": "/docs/layout/columns"
      },
      {
        "label": "Gutters",
        "link": "/docs/layout/gutters"
      }
    ]
  },
  {
    "label": "Components",
    "icon": "ri-suitcase-line",
    "submenu": [
      {
        "label": "Accordion",
        "link": "/docs/com/accordions"
      },
      {
        "label": "Alerts",
        "link": "/docs/com/alerts"
      },
      {
        "label": "Avatars",
        "link": "/docs/com/avatars"
      },
      {
        "label": "Badge",
        "link": "/docs/com/badge"
      },
      {
        "label": "Breadcrumbs",
        "link": "/docs/com/breadcrumbs"
      },
      {
        "label": "Buttons",
        "link": "/docs/com/buttons"
      },
      {
        "label": "Cards",
        "link": "/docs/com/cards"
      },
      {
        "label": "Carousel",
        "link": "/docs/com/carousel"
      },
      {
        "label": "Dropdown",
        "link": "/docs/com/dropdown"
      },
      {
        "label": "Images",
        "link": "/docs/com/images"
      },
      {
        "label": "List Group",
        "link": "/docs/com/listgroup"
      },
      {
        "label": "Markers",
        "link": "/docs/com/markers"
      },
      {
        "label": "Modal",
        "link": "/docs/com/modal"
      },
      {
        "label": "Nav & Tabs",
        "link": "/docs/com/navtabs"
      },
      {
        "label": "Offcanvas",
        "link": "/docs/com/offcanvas"
      },
      {
        "label": "Pagination",
        "link": "/docs/com/pagination"
      },
      {
        "label": "Placeholders",
        "link": "/docs/com/placeholders"
      },
      {
        "label": "Popovers",
        "link": "/docs/com/popovers"
      },
      {
        "label": "Progress",
        "link": "/docs/com/progress"
      },
      {
        "label": "Spinners",
        "link": "/docs/com/spinners"
      },
      {
        "label": "Toast",
        "link": "/docs/com/toasts"
      },
      {
        "label": "Tooltips",
        "link": "/docs/com/tooltips"
      },
      {
        "label": "Tables",
        "link": "/docs/com/tables"
      }
    ]
  },
  {
    "label": "Forms",
    "icon": "ri-list-check-2",
    "submenu": [
      {
        "label": "Text Elements",
        "link": "/docs/form/elements"
      },
      {
        "label": "Selects",
        "link": "/docs/form/selects"
      },
      {
        "label": "Checks & Radios",
        "link": "/docs/form/checksradios"
      },
      {
        "label": "Range",
        "link": "/docs/form/range"
      },
      {
        "label": "Pickers",
        "link": "/docs/form/pickers"
      },
      {
        "label": "Layouts",
        "link": "/docs/form/layouts"
      }
    ]
  },
  {
    "label": "Charts & Graphs",
    "icon": "ri-bar-chart-2-line",
    "submenu": [
      {
        "label": "ApexCharts",
        "link": "/docs/chart/apex"
      },
      {
        "label": "Chartjs",
        "link": "/docs/chart/chartjs"
      }
    ]
  },
  {
    "label": "Maps & Icons",
    "icon": "ri-stack-line",
    "submenu": [
      {
        "label": "Leaflet Maps",
        "link": "/docs/map/leaflet"
      },
      {
        "label": "Remixicon",
        "link": "/docs/icon/remix"
      }
    ]
  },
  {
    "label": "Utilities",
    "icon": "ri-briefcase-4-line",
    "submenu": [
      {
        "label": "Background",
        "link": "/docs/util/background"
      },
      {
        "label": "Border",
        "link": "/docs/util/border"
      },
      {
        "label": "Colors",
        "link": "/docs/util/colors"
      },
      {
        "label": "Divider",
        "link": "/docs/util/divider"
      },
      {
        "label": "Flex",
        "link": "/docs/util/flex"
      },
      {
        "label": "Sizing",
        "link": "/docs/util/sizing"
      },
      {
        "label": "Spacing",
        "link": "/docs/util/spacing"
      },
      {
        "label": "Opacity",
        "link": "/docs/util/opacity"
      },
      {
        "label": "Position",
        "link": "/docs/util/position"
      },
      {
        "label": "Typography",
        "link": "/docs/util/typography"
      },
      {
        "label": "Shadows",
        "link": "/docs/util/shadows"
      },
      {
        "label": "Extras",
        "link": "/docs/util/extras"
      }
    ]
  }
];

export {maintenance, transactions, info, solpagMenu, MantenimientosGeneralesMenu, invoice, uiElementsMenu, pagesMenu};