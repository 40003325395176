import React from "react";
import { Button } from "react-bootstrap";
import "../../styles/info.css";

export default function SupportScreen() {
  const handleSupportClick = () => {
    const phoneNumber = "50661372755"; // Número de WhatsApp para soporte
    const message = encodeURIComponent("Hola, necesito soporte con el servicio.");
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, "_blank");
  };

  return (
    <div
      className="support-screen"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      <h1 style={{ fontSize: "2rem", marginBottom: "20px" }}>¿Necesitas soporte?</h1>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Si necesitas ayuda, contáctanos a través de nuestro enlace de WhatsApp.
      </p>
      <Button
        variant="success"
        size="lg"
        onClick={handleSupportClick}
        style={{ fontSize: "1.2rem", padding: "10px 20px" }}
      >
        Ir a WhatsApp
      </Button>
    </div>
  );
}
