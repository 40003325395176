import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Swal from "sweetalert2"; // Importar SweetAlert2
import logo from '../../styles/assets/img/Logo.jpg';
import { useAuthStore } from '../../hooks';


export default function Signup() {
  // Estado para los valores del formulario
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    lastname: "",
    telefono: "",
    codigoGenerado: "",
    nombreEmpresa: "",
    descripcion: "",
    direccion: "",
    codigoPostal: "",
    telefonoEmpresa: "",
    correoEmpresa: "",
    estadoProvincia: ""
  });

  // const [formData, setFormData] = useState({
  //   email: "chepe352013@gmail.com",
  //   identification: "604240231",
  //   password: "Airi9516!",
  //   confirmPassword: "Airi9516!",
  //   name: "José Luis",
  //   lastname: "Molina Cascante",
  //   telefono: "61372755",
  //   codigoGenerado: "",
  //   nombreEmpresa: "JMSOFTCR",
  //   descripcion: "Servicios de informática",
  //   direccion: "Santa Martha, Esparza, Puntarenas",
  //   codigoPostal: "60203",
  //   telefonoEmpresa: "61372755",
  //   correoEmpresa: "jose.molina.cascante@gmail.com",
  //   estadoProvincia: "Puntarenas"
  // });

  // Estado para la validación de campos vacíos
  const [errors, setErrors] = useState({});

  // Estado para controlar si el botón "Crear Cuenta" está deshabilitado
  const [isRegisterButtonEnabled, setIsRegisterButtonEnabled] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]); // Estado para almacenar las opciones de países

  const [selectedCountry, setSelectedCountry] = useState('');
  const [prefijo, setSelectedprefijo] = useState('');

  const { GetCodeRegister, startRegister, getCountries } = useAuthStore();


  // Manejar cambios en los inputs
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value // Actualiza el estado de acuerdo al input
    });
  };

  // Validar formulario
  const validateForm = () => {
    let newErrors = {};
    // Verificar que todos los campos no estén vacíos
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = true;
      }
    });

    setErrors(newErrors);
    // Si no hay errores, el formulario es válido
    return Object.keys(newErrors).length === 0;
  };

  // Función para validar el formato del correo electrónico
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Función para validar el formato del teléfono
  const isValidPhoneNumber = (phoneNumber) => {
    // Esta expresión regular valida que el número de teléfono tenga entre 7 y 15 dígitos numéricos
    const phoneRegex = /^\d{7,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    // Expresión regular para verificar que la contraseña contenga al menos:
    // 8 caracteres, una letra minúscula, una letra mayúscula, un número y un carácter especial
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  // Función para manejar el submit del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    //debugger
    // Validar los campos de correo electrónico
    if (!isValidEmail(formData.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, ingresa una dirección de correo electrónico válida en todos los campos.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (!isValidEmail(formData.correoEmpresa)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, ingresa una dirección de correo electrónico válida en todos los campos.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    // Validar el formato de los números de teléfono
    if (!isValidPhoneNumber(formData.telefono)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, ingresa un número de teléfono válido.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    // Validar el formato de los números de teléfono
    if (!isValidPhoneNumber(formData.telefonoEmpresa)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, ingresa un número de teléfono válido.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    // Validar que la contraseña sea segura
    if (!isValidPassword(formData.password)) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial. (EJEMPLO: Abc1234@)',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (!validateForm()) {
      debugger
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor completa todos los campos y asegúrate de que las contraseñas coincidan.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'Contraseña no coinciden',
        confirmButtonText: 'Ok'
      });
      return;
    }
    // Llamar a la función startRegister con los campos del formulario
    startRegister({
      name: formData.name,
      lastname: formData.lastname,
      identification: formData.identification,
      email: formData.email,
      password: formData.password,


      telefono: formData.telefono,
      pais: selectedCountry,
      estadoProvincia: formData.estadoProvincia,


      codigoGenerado: formData.codigoGenerado,
      nombreEmpresa: formData.nombreEmpresa,
      descripcion: formData.descripcion,
      direccion: formData.direccion,
      codigoPostal: formData.codigoPostal,
      telefonoEmpresa: formData.telefonoEmpresa,
      correoEmpresa: formData.correoEmpresa
    });

    // Aquí puedes enviar los datos a tu servidor (ejemplo con fetch o axios)
    // console.log("Formulario enviado con éxito:", formData);
  };

  const handleObtenerCodigo = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

    // Validar el correo electrónico
    if (!isValidEmail(formData.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formato del correo electrónico no es válido. Por favor, ingresa una dirección de correo válida.',
        confirmButtonText: 'Ok'
      });
      return; // Salir de la función si el correo no es válido
    }

    try {
      await GetCodeRegister({ email: formData.email });
      // Habilitar el botón "Crear Cuenta" al generar el código
      setIsRegisterButtonEnabled(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al obtener el código, por favor intenta nuevamente.',
        confirmButtonText: 'Ok'
      });
    }
  };

  const getAllCountries = async () => {
    try {
      const data = await getCountries();
      const options = data.map(country => ({
        value: country.id,  // Usar 'code' como valor
        label: country.name,   // Mostrar el 'name' del país
        prefix: country.code_country // Agregar el campo 'prefijo' o 'prefix'

      }));
      // debugger
      setCountryOptions(options);
    } catch (error) {
      console.error("Error al obtener países: ", error);
    }
  };


  useEffect(() => {
    getAllCountries(); // Llamada para obtener países
  }, []);


  return (
    <div className="page-sign" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card className="card-sign" style={{ boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.2)', width: '80%' }}>
        <Card.Header style={{ textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ maxWidth: '300px', height: 'auto', alignItems: 'center' }} />
        </Card.Header>
        <Card.Body style={{ margin: '5%' }}>
          <Form onSubmit={handleSubmit}>
            <h4>Información Personal</h4>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Label>Nombre*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Ingresa tu nombre"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ borderColor: errors.name ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Apellidos*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  placeholder="Ingresa tus apellidos"
                  value={formData.lastname}
                  onChange={handleChange}
                  style={{ borderColor: errors.lastname ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Identificación*</Form.Label>
                <Form.Control
                  type="text"
                  name="identification"
                  placeholder="Ingresa tu identificacion"
                  value={formData.identification}
                  onChange={handleChange}
                  style={{ borderColor: errors.identification ? 'red' : '' }}
                  maxLength={20}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Label>Correo Personal*</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Ingresa tu correo"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ borderColor: errors.email ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Contraseña*</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  value={formData.password}
                  onChange={handleChange}
                  style={{ borderColor: errors.password ? 'red' : '' }}
                  maxLength={30}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Repite Contraseña*</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Re-ingresa tu contraseña"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  style={{ borderColor: errors.confirmPassword ? 'red' : '' }}
                  maxLength={30}
                />
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Form.Label>Prefijo</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  placeholder="prefijo"
                  value={prefijo}
                  onChange={handleChange}
                  style={{ borderColor: errors.prefijo ? 'red' : '' }}
                  disabled
                />
              </Col>
              <Col md={3}>
                <Form.Label>Teléfono*</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  placeholder="Ingresa tu número de teléfono"
                  value={formData.telefono}
                  onChange={handleChange}
                  style={{ borderColor: errors.telefono ? 'red' : '' }}
                  maxLength={20}
                />
              </Col>
              <Col md={4}>
                <Form.Label>País*</Form.Label>
                <Select
                  id="country-select"
                  value={countryOptions.find(option => option.value === selectedCountry)}
                  onChange={(selectedOption) => {
                    console.log(selectedOption); // Verifica qué valor se selecciona
                    setSelectedCountry(selectedOption.value);
                    setSelectedprefijo(selectedOption.prefix);
                    console.log("Pais:" + selectedCountry);
                    // Asegúrate de que selectedOption tenga el formato correcto
                  }}
                  // onChange={(selectedOption) => setSelectedCountry(selectedOption.value)}
                  options={countryOptions}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: errors.pais ? 'red' : base.borderColor,
                    }),
                  }}
                  placeholder="--Selecciona un país--"
                  isSearchable
                  classNamePrefix="react-select"
                />
              </Col>
              {/* <Col md={4}>
                  <Form.Label>País*</Form.Label>
                  <Form.Control
                    type="text"
                    name="pais"
                    placeholder="Ingresa el país"
                    value={formData.pais}
                    onChange={handleChange}
                    style={{ borderColor: errors.pais ? 'red' : '' }}
                  />
              </Col> */}
              <Col md={4}>
                <Form.Label>Estado / Provincia*</Form.Label>
                <Form.Control
                  type="text"
                  name="estadoProvincia"
                  placeholder="Ingresa tu Estado / Provincia"
                  value={formData.estadoProvincia}
                  onChange={handleChange}
                  style={{ borderColor: errors.estadoProvincia ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
            </Row>
            <br />
            <h4>Datos Empresa</h4>
            <p><strong>En caso de no poseer datos de empresa, utilizar los datos personales anteriores</strong></p>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Label>Nombre Empresa*</Form.Label>
                <Form.Control
                  type="text"
                  name="nombreEmpresa"
                  placeholder="Ingresa tu nombre de empresa"
                  value={formData.nombreEmpresa}
                  onChange={handleChange}
                  style={{ borderColor: errors.nombreEmpresa ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Descripcion*</Form.Label>
                <Form.Control
                  type="text"
                  name="descripcion"
                  placeholder="Ingresa tu descripción"
                  value={formData.descripcion}
                  onChange={handleChange}
                  style={{ borderColor: errors.descripcion ? 'red' : '' }}
                  maxLength={100}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Dirección*</Form.Label>
                <Form.Control
                  type="text"
                  name="direccion"
                  placeholder="Ingresa tu dirección"
                  value={formData.direccion}
                  onChange={handleChange}
                  style={{ borderColor: errors.direccion ? 'red' : '' }}
                  maxLength={100}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Label>Código Postal*</Form.Label>
                <Form.Control
                  type="text"
                  name="codigoPostal"
                  placeholder="Ingresa tu código postal"
                  value={formData.codigoPostal}
                  onChange={handleChange}
                  style={{ borderColor: errors.codigoPostal ? 'red' : '' }}
                  maxLength={20}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Teléfono Empresa*</Form.Label>
                <Form.Control
                  type="text"
                  name="telefonoEmpresa"
                  placeholder="Ingresa tu teléfono de empresa"
                  value={formData.telefonoEmpresa}
                  onChange={handleChange}
                  style={{ borderColor: errors.telefonoEmpresa ? 'red' : '' }}
                  maxLength={20}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Correo Empresa*</Form.Label>
                <Form.Control
                  type="text"
                  name="correoEmpresa"
                  placeholder="Ingresa tu correo empresarial"
                  value={formData.correoEmpresa}
                  onChange={handleChange}
                  style={{ borderColor: errors.correoEmpresa ? 'red' : '' }}
                  maxLength={50}
                />
              </Col>
            </Row>

            <div className="mb-4" style={{ color: '#2796cf' }}>
              <small><strong>Debes generar un código que será enviado a tu correo personal ingresado anteriormente para poder proceder con el registro.</strong></small>
            </div>

            <Row className="mb-3">
              <Col md={4} className="text-end">
                <Button variant="info" type="button" onClick={handleObtenerCodigo}>
                  Obtener código
                </Button>
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="codigoGenerado"
                  placeholder="Código generado"
                  value={formData.codigoGenerado}
                  onChange={handleChange}
                  maxLength={10}
                />
                <div>
                  <p>Ingresar código generado enviado al correo</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="d-grid gap-2">
                <Button
                  id="btn_register"
                  variant="primary"
                  type="submit"
                  className="btn-sign"
                  disabled={!isRegisterButtonEnabled} // Controlar el estado del botón aquí
                >
                  Crear Cuenta
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <br />
                <span>¿Ya tienes una cuenta? </span>
                <Link to="/signin"><strong>Inicia sesión</strong></Link>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
