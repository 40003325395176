import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthStore, useForm } from "../../hooks";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import logo from "../../styles/assets/img/Logo.jpg";
import "./style.css";

const RecoveryFormFields = {
  newPassword: "",
  ReTypePassword: "",
};

export default function RecoverPasswordScreen() {
  const { errorMessage, resetPassword } = useAuthStore();
  const { newPassword, ReTypePassword, onInputChange: onRecoveryInputChange } = useForm(RecoveryFormFields);
  // Estado para almacenar el userId
  const [userId, setUserId] = useState(null);

    // Obtener el token de la URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    useEffect(() => {
      if (token) {
        console.log("Token recibido:", token);  // Verifica el token en la consola
        try {
          const decoded = jwtDecode(token); // Decodificamos el token
          console.log("Decoded token:", decoded);  // Verifica el contenido decodificado
          setUserId(decoded.userId); // Almacenamos el userId en el estado
        } catch (error) {
          console.error("Error al decodificar el token:", error);
          Swal.fire("Error", "El token no es válido", "error");
        }
      }
    }, [token]);
    

  const loginSubmit = (event) => {
    event.preventDefault();
    if(newPassword !== ReTypePassword){
      Swal.fire("Error en el proceso", "Las contraseñas no coinciden", "error");
      return;
    } else {
      // Validar que la contraseña sea segura
          if (!isValidPassword(newPassword)) {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial. (EJEMPLO: Abc1234@)',
              confirmButtonText: 'Ok'
            });
            return;
          } else {
            resetPassword({ id: userId, password: newPassword });
          }
    }
  };
  
  const isValidPassword = (password) => {
    // Expresión regular para verificar que la contraseña contenga al menos:
    // 8 caracteres, una letra minúscula, una letra mayúscula, un número y un carácter especial
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      Swal.fire("Error en la autenticación", errorMessage, "error");
    }
  }, [errorMessage]);

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ boxShadow: "0px 4px 80px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header>
          <img src={logo} alt="Logo" style={{ width: "100%", height: "auto", alignItems: "center" }} />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={loginSubmit} style={{ margin: "5%" }}>
            <h1>Recuperar contraseña</h1>
            <div className="mb-4">
              <Form.Label style={{ color: "#2796cf" }}>Nueva Contraseña *</Form.Label>
              <Form.Control
                type="password"
                placeholder="Ingrese su contraseña"
                name="newPassword"
                value={newPassword}
                onChange={onRecoveryInputChange}
              />
            </div>
            <div className="mb-4">
              <Form.Label style={{ color: "#2796cf" }}>Repetir Contraseña *</Form.Label>
              <Form.Control
                type="password"
                placeholder="Repita su contraseña"
                name="ReTypePassword"
                value={ReTypePassword}
                onChange={onRecoveryInputChange}
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              Cambiar contraseña
            </Button>
          </Form>
        </Card.Body>
        <hr />
        <Card.Footer style={{ color: "#2796cf" }}>
          ¿No tiene cuenta?{" "}
          <Link to="/pages/signup">
            <strong>Registrarse</strong>
          </Link>
        </Card.Footer>

        <div className="separator">
          <span>O</span>
        </div>

        <Card.Footer style={{ color: "#2796cf" }}>
        <br />
                <span>¿Ya tienes una cuenta? </span>
                <Link to="/signin"><strong>Inicia sesión</strong></Link>
         
        </Card.Footer>

        <Card.Footer>
          <Card.Title style={{ color: "#2796cf" }}>Powered by JMSOFTCR &copy; 2025</Card.Title>
        </Card.Footer>
      </Card>
    </div>
  );
}
