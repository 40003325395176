import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import { Grid, _ } from "gridjs-react";
import { Card, Button, Container } from "react-bootstrap";
import { useInventoryProductStore } from '../../hooks/InventoryProduct/useInventoryProductStore';
import * as XLSX from 'xlsx'; // Importar toda la biblioteca como un objeto XLSX
import jsPDF from 'jspdf'; // Importar jspdf
import 'jspdf-autotable'; // Importar jspdf-autotable


export default function Standard() {
  const [Data, setData] = useState([]); // Estado local para almacenar los datos de la lista
  const { list } = useInventoryProductStore();

  useEffect(() => {
      getList();
  }, []);

  const getList = async () => {
    try {
      const data = await list();
      setData(data);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(Data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "InventoryProductData.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF(); // Crear un nuevo documento PDF
    doc.autoTable({ // Utilizar autotable para crear una tabla en el PDF
      head: [['Name', 'Description', 'Status']], // Encabezados de las columnas
      body: Data.map(({ name, description, status }) => [name, description, status]) // Datos de las filas
    });
    doc.save('ProductData.pdf'); // Guardar el documento PDF
  };

  return (
    <React.Fragment>
      <Container fluid="md" className="mb-5">
        <Header />
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Inventario Producto</h4>
            </div>
            <div>
              <Button variant="dark" onClick={exportToExcel} className="ml-2">Exportar Excel</Button>
              <Button variant="dark" onClick={exportToPDF} className="ml-2">Exportar PDF</Button>
            </div>
            <div>
              <Button variant="success" as={Link} to="/pages/inventoryEdit">Agregar</Button>
            </div>
          </div>
          <Card.Body style={{ overflowX: 'auto' }}>
            <Grid
              data={Data}
              columns={[
                { name: 'warehouse_id', hidden: true },
                { id: 'warehouse', name: 'Almacen' },
                { id: 'product_id', name: 'Producto ID', hidden: true },
                { id: 'name', name: 'Nombre' },
                { id: 'description', name: 'Descripcion', hidden: true },
                { id: 'code', name: 'Codigo' },
                { id: 'code_bar', name: 'Codigo Barra' },
                { id: 'price', name: 'Precio' },
                { id: 'qty', name: 'QTY' },
                { id: 'tax', name: 'Impuesto' },
                { id: 'discount', name: 'Descuento' },
                { id: 'Subtotal', name: 'Subtotal' },
                { id: 'tax_apply', name: 'Impuesto Aplicado' },
                { id: 'discount_total', name: 'Total Descuento' },
                { id: 'total', name: 'Total' }
              ]}
              search={true}
              pagination={{
                enabled: true,
                limit: 5
              }}
              sort={true}
              resizable={true}
              language={{
                search: {
                  placeholder: 'Write to search...',
                },
                sort: {
                  sortAsc: 'Ascending column order.',
                  sortDesc: 'Descending column order.',
                },
                pagination: {
                  previous: 'Previous',
                  next: 'Next',
                  navigate: (page, pages) => `Page ${page} of ${pages}`,
                  page: (page) => `Page ${page}`,
                  showing: 'Showing of',
                  of: 'of',
                  to: 'to',
                  results: 'records',
                },
                loading: 'Loading...',
                noRecordsFound: 'No matches found.',
                error: 'An error occurred while obtaining the data.',
              }}
              className={{
                table: 'table table-bordered mb-0'
              }}
            />
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
