import { useSelector } from 'react-redux';
import services from '../../services/servicesTeso';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useMensajesStore } from '../Generales_Mensajes_Validaciones/useMensajesStore';
import { useAuthStore } from '../useAuthStore';


export const useProductStore = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { SuccessMensaje, SuccessDeleteMensaje, abrirCargar } = useMensajesStore();
    const { checkAuthToken } = useAuthStore();


    let userId = sessionStorage.getItem('uid');



    //Lista la información que se muestra en banco.js
    const list = async () => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            //debugger
            //abrirCargar();
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            //console.log("url:" + services.API.Product.List);
            const { data } = await axios.post(services.API.Product.List, 
             { userId }, {headers: headerAxios});            
            if (data.respuesta === "success") {
                for (var i = 0; i < data.Data.length; i++) {
                    if (data.Data[i].status === 1) {
                        data.Data[i].status = "Activo";
                    } if (data.Data[i].status == 0) {
                        data.Data[i].status = "Inactivo";
                    }
                }
                return data.Data;
                //dispatch(setBancos({ bancos: data.data }));
                //cerrarCargar();
            } else {
                if (data.respuesta_tipo === "warning") {
                    NoPermisoMensaje();
                }
                if (data === "") {
                    NoPermisoMensaje();
                }
            }
        } catch (error) {
            ErrorMensaje();
            console.log("error:" + error);
            throw error; // Puedes propagar el error para manejarlo en el componente
        }
    }

    //Lista la información que se muestra en banco.js
    const listDataForm = async () => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            //debugger
            //abrirCargar();
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            //console.log("url:" + services.API.Product.List);
            const { data } = await axios.post(services.API.Product.listDataForm, 
             { userId }, {headers: headerAxios});            
            if (data.respuesta === "success") {
                return data.Data;
                //dispatch(setBancos({ bancos: data.data }));
                //cerrarCargar();
            } else {
                if (data.respuesta_tipo === "warning") {
                    NoPermisoMensaje();
                }
                if (data === "") {
                    NoPermisoMensaje();
                }
            }
        } catch (error) {
            ErrorMensaje();
            console.log("error:" + error);
            throw error; // Puedes propagar el error para manejarlo en el componente
        }
    }


    //Actualiza e inserta. 
    const Upsert = async ({ id, productName, categoryId, unitTypeId, productCode, productCodeBar, productDescription, productPrice, productTax, productImage, productMinQty }) => {
        //debugger

        const token = localStorage.getItem('token');
        // if (status == "1" || status == true) {
        //     status = true;
        // } else {
        //     status = false;
        // }
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                        }
            if(id == undefined || id == 0){
                  data  = await axios.post(services.API.Product.Create, { userId,  productName, categoryId, unitTypeId, productCode, productCodeBar, productDescription, productPrice, productTax, productImage, productMinQty }, { headers: headerAxios });
            } else {
                  data  = await axios.post(services.API.Product.Update, { id, productName, categoryId, unitTypeId, productCode, productCodeBar, productDescription, productPrice, productTax, productImage, productMinQty }, { headers: headerAxios });
            }
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                console.log("data: " + data);
                SuccessMensaje();
                //dispatch(upSertBanco({ bancos: data.data }));
                navigate('/pages/product');
            }
            if (data.data.respuesta === "warning") {
                Swal.fire(
                    'Data duplicated',
                    'Input another data.',
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    //Get Data to update.
    const getDataUpdate = async (id) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Product.GetData, { id }, { headers: headerAxios });
            if (data.respuesta === "success") {
                //history.pushState('')
                //history.push(`/pages/productEdit/${id}`, { productData });
                //navigate('/pages/bancoEditar');
                return data.Object
                // navigate('/pages/bancoEditar');
                // dispatch(setBancoDataForm({ getData: data.data.banco }));
                // dispatch(setPaises({ paises: data.data.paises }));

            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

    const Delete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás deshacer esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Aquí se ejecuta la acción si el usuario confirma
                const token = localStorage.getItem('token');
                checkAuthToken();
                try {
                    const headerAxios = {
                        'content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                    const { data } = await axios.post(services.API.Product.Delete, { id }, { headers: headerAxios });
                    if (data.respuesta === "success") {
                        console.log("data: " + data);
                        SuccessDeleteMensaje();
                    }
                    if (data.respuesta_tipo === "warning") {
                        Swal.fire(
                            'Problema con el Banco',
                            data.respuesta_detalle,
                        )
                    }
                } catch (error) {
                    console.log("error:" + error);
                    ErrorMensaje();
                }
            }
        });
    }

    // //Elimina 
    // const Delete = async (id) => {
    //     //debugger
    //     const token = localStorage.getItem('token');
    //     checkAuthToken();
    //     try {
    //         const headerAxios = {
    //             'content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         }
    //         const { data } = await axios.post(services.API.Product.Delete, { id }, { headers: headerAxios });
    //         if (data.respuesta === "success") {
    //             console.log("data: " + data);
    //             SuccessDeleteMensaje();
    //         }
    //         if (data.respuesta_tipo === "warning") {
    //             Swal.fire(
    //                 'Problema con el Banco',
    //                 data.respuesta_detalle,
    //             )
    //         }
    //     } catch (error) {
    //         console.log("error:" + error);
    //         ErrorMensaje();
    //     }
    // }


    const NoPermisoMensaje = async () => {
        Swal.fire(
            'No posee permisos para realizar esta acción.',
            'Contacte un administrador.',
            'question'
        )
    }

    const ErrorMensaje = async () => {
        Swal.fire(
            'Ha ocurrido un error.',
            'Contacte un administrador.',
            'error'
        )
    }

    return {
        //* Métodos
        list,
        listDataForm,
        Upsert,
        getDataUpdate,
        Delete
    }

}