import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useCustomerStore } from '../../hooks/Mantenimiento_Generales/useCustomerStore';


export default function Standard() {
  const [formData, setFormData] = useState({
    paymentId: 0,
    name: '',
    paymentDate: '',
    amount: 0,
    paymentMethod: '',
    statusPayment: '',
    nextPaymentDate: '',
    notify: 0,
    notes: ''
  });

  const { UpsertPayment } = useCustomerStore();


  const location = useLocation();
  const customerData = location.state ? location.state.customerData[0] || {} : {};

  useEffect(() => {
    if (Object.keys(customerData).length > 0) {
      setFormData({
        paymentId: customerData.id || 0,
        name: customerData.name || '',
        paymentDate: customerData.payment_date || '',
        amount: customerData.amount || 0,
        paymentMethod: customerData.payment_method || '',
        statusPayment: customerData.statusPayment || '',
        nextPaymentDate: customerData.next_payment_date || '',
        notify: customerData.notify || 0,
        notes: customerData.notes || '',
      });
    }
  }, [customerData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    debugger
    UpsertPayment({ paymentId: formData.paymentId, paymentDate: formData.paymentDate, amount: formData.amount, paymentMethod: formData.paymentMethod, statusPayment: formData.statusPayment, nextPaymentDate: formData.nextPaymentDate, notify: formData.notify, notes: formData.notes});
    // Aquí puedes enviar formData a donde lo necesites
    console.log(formData);
  };

  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Control Pagos Clientes</h4>
            </div>
          </div>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <Row className="g-4">
                <Form.Control type="hidden" name="paymentId" value={formData.paymentId} onChange={handleInputChange} disabled required />
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="name">Nombre Completo</Form.Label>
                  <Form.Control type="text" placeholder="Name" name="name" value={formData.name} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="paymentDate">Fecha de Pago</Form.Label>
                  <Form.Control type="date" name="paymentDate" value={formData.paymentDate} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="amount">Monto</Form.Label>
                  <Form.Control type="number" placeholder="Amount" name="amount" value={formData.amount} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="paymentMethod">Método de Pago</Form.Label>
                  <Form.Control type="text" placeholder="Payment Method" name="paymentMethod" value={formData.paymentMethod} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                <Form.Label htmlFor="statusPayment">Esado de Pago</Form.Label>
                <Form.Control 
                  as="select" 
                  name="statusPayment" 
                  value={formData.statusPayment} 
                  onChange={handleInputChange} 
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Paid">Pagado</option>
                  <option value="Pending">Pendiente</option>
                  <option value="Overdue">Vencido</option>
                </Form.Control>
              </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="nextPaymentDate">Fecha Próximo Pago</Form.Label>
                  <Form.Control type="date" placeholder="Next Payment Date" name="nextPaymentDate" value={formData.nextPaymentDate} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="notes">Notas</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Notes" name="notes" value={formData.notes} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                <Form.Label htmlFor="notify">Estado Notificado <strong>(Para volver a notificar debe de estar en NO y estado = Vencido)</strong> </Form.Label>
                <Form.Control 
                  as="select" 
                  name="notify" 
                  value={formData.notify} 
                  onChange={handleInputChange} 
                  required
                >
                  <option value="">Select Status</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Control>
              </Form.Group>
                
              </Row>
              <hr />
              <div>
                <Col sm="12">
                  <Button type="submit" variant="success" className="btn-sign">Guardar</Button>
                  <Button type="submit" variant="danger" as={Link} to="/pages/customerPayment" className="btn-sign">Cancelar</Button>
                </Col>
              </div>
            </Form>
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
